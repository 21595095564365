import React from "react";
import './style.css'

function Footer(){
    return(
        <footer id="footer">
          <p>Copyright Daron Schmit 2021</p>
        </footer>
        )
}

export default Footer;